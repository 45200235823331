export const MainConfig = {
    AppDetails: {
        app_name: "BalyTaxi",
        app_description: "BalyTaxi Taxi Booking App",
        app_identifier: "com.exicube.balytaxi",
        ios_app_version: "2.1",
        android_app_version: 21
    },
    FirebaseConfig: {
        apiKey: "AIzaSyBGaTk2tSOdIHi-rFm00vZw1HWS1WpXWgE",
        authDomain: "debaly-taxi.firebaseapp.com",
        databaseURL: "https://debaly-taxi-default-rtdb.firebaseio.com",
        projectId: "debaly-taxi",
        storageBucket: "debaly-taxi.appspot.com",
        messagingSenderId: "625961520174",
        appId: "1:625961520174:web:14055b21522282f424e3bf",
        measurementId: "G-QHH066KH9K"
    },
    Google_Map_Key: "AIzaSyA4KlJErprjimDA3_o9UBa8GB8PcCGbEaQ",
    facebookAppId: "441465090205587"
}
