export const features =  {
    AllowCriticalEditsAdmin:true,
    AllowCountrySelection:true,
    WebsitePagesEnabled:true,
    MobileLoginEnabled:true,
    FacebookLoginEnabled:true,
    AppleLoginEnabled:false,
    CompanyName: 'debaly App Solutions',
    CompanyWebsite:"https://debaly.com",
    CompanyTerms: "https://debaly.com",
    TwitterHandle: "https://debaly.com",
    FacebookHandle: "https://www.facebook.com/debalySoft",
    AppleStoreLink: "https://debaly.com",
    PlayStoreLink: "https://debaly.com"
};
