import React from "react";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";

import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from './views/AuthLoading';
import {Provider} from "react-redux";
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import Dashboard from './views/Dashboard';
import CarTypes from './views/CarTypes';
import AddBookings from './views/AddBookings';
import Promos from './views/Promos';
import Users from './views/Users';
import Referral from './views/Referral';
import Notifications from './views/Notifications';
import DriverEarning from './views/DriverEarning';
import Earningreports from './views/Earningreports';
import Settings from './views/Settings';
import Withdraws from './views/Withdraws';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import {
    store,
    FirebaseProvider
} from "common";

import {features} from 'config';

var hist = createBrowserHistory();

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#e47be8',
            main: '#7453b0',
            dark: '#68266a'
        }
    }
});
function App() {
    return (
        <MuiThemeProvider  theme = { theme }>
            <Provider store={store}>
                <FirebaseProvider>
                    <AuthLoading>
                        <Router history={hist}>
                            <Switch>
                                <ProtectedRoute exact component={BookingHistory} path="/bookings"
                                                permit={"rider,admin,driver"}/>
                                <ProtectedRoute exact component={MyProfile} path="/profile"
                                                permit={"rider,admin,driver"}/>
                                <ProtectedRoute exact component={Dashboard} path="/dashboard" permit={"admin"}/>
                                <ProtectedRoute exact component={CarTypes} path="/cartypes" permit={"admin"}/>
                                <ProtectedRoute exact component={AddBookings} path="/addbookings" permit={"admin"}/>
                                <ProtectedRoute exact component={Promos} path="/promos" permit={"admin"}/>
                                <ProtectedRoute exact component={Users} path="/users" permit={"admin"}/>
                                <ProtectedRoute exact component={DriverEarning} path="/driverearning" permit={"admin"}/>
                                <ProtectedRoute exact component={Referral} path="/referral" permit={"admin"}/>
                                <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin"}/>
                                <ProtectedRoute exact component={Earningreports} path="/earningreports"
                                                permit={"admin"}/>
                                <ProtectedRoute exact component={Withdraws} path="/withdraws" permit={"admin"}/>
                                <ProtectedRoute exact component={Settings} path="/settings" permit={"admin"}/>
                                <Route path="/about-us" component={AboutUs}/>
                                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                                {features.WebsitePagesEnabled ?
                                    <Route path="/login" component={LoginPage}/>
                                    : null}
                                {features.WebsitePagesEnabled ?
                                    <Route path="/" component={LandingPage}/>
                                    :
                                    <Route path="/" component={LoginPage}/>
                                }
                            </Switch>
                        </Router>
                    </AuthLoading>
                </FirebaseProvider>
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;
